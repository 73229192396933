import logo from "./logo.svg";
import "./App.scss";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Timeline from "Pages/Timeline";
import ProfileCard from "Components/Cards/ProfileCard";
import Academics from "Pages/Academics";
import Projects from "Pages/Projects";
import Resume from "Pages/Resume";
import CP from "Pages/CP";
import Navbar from "Components/Navbar";
import { useState } from "react";
import Contact from "Pages/Contact";

function Layout() {
  return (
    <div className="App ">
      <header className="bg-[#282c34] text-white pt-20 min-h-screen pb-10">
        <div className="flex flex-col gap-5 items-center">
          <ProfileCard />
          <Navbar />
          <Outlet />
        </div>
      </header>
    </div>
  );
}
function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Timeline />} />
          <Route path="/academics" element={<Academics />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/cp_stats" element={<CP />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
