import { useState } from "react";
import notes from "Database/notes.json";
import repositories from "Database/repositories.json";
import { Zoom } from "@mui/material";
import ImageLoader from "Components/ImageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import { Helmet } from "react-helmet";

function Repositories() {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan | Academics</title>
        <meta
          name="description"
          content="Study material for BUET CSE courses"
        />
        <meta
          name="keywords"
          content="buet, buet cse, buet cse course, bangladesh cse, university cse, cse notes, eee 163, math 145, phy 109, cse 409"
        />
        <meta name="author" content="Mahir Labib Dihan" />
        <link rel="canonical" href="https://mahirlabibdihan.tech/academics" />

        {/* <!--open graphs--> */}
        <meta property="og:title" content="Mahir Labib Dihan | Academics" />
        <meta
          property="og:description"
          content="Study material for BUET CSE courses"
        />
        <meta property="og:image" content="%PUBLIC_URL%/ohornish_19.jpg" />
        <meta property="og:image:alt" content="Mahir Labib Dihan" />
        <meta property="og:image:type" content="image/jpg" />
        <meta
          property="og:url"
          content="https://mahirlabibdihan.tech/academics"
        />
      </Helmet>
      <div className="flex flex-col rounded-[10px] border-2 md:border-4 border-black w-full">
        <div
          className="bg-black w-full p-4 pt-2 text-left font-mono cursor-pointer"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <div className="flex flex-row space-between w-full items-center">
            <h1 className="text-lg md:text-2xl font-bold w-full">
              Repositories
            </h1>
            <IconButton>
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon
                  icon={expanded ? faChevronUp : faChevronDown}
                  color="white"
                />
              </div>
            </IconButton>
          </div>
          <h3 className="text-sm md:text-lg">
            I also maintained github repositories for the sessional courses.
          </h3>
        </div>
        {expanded && (
          <div className="flex flex-col gap-5 justify-start items-start w-full">
            {repositories.map((e) => (
              <div className="flex flex-col gap-5 p-4 text-left">
                <h1 className="text-base md:text-xl font-bold"># {e.term}</h1>
                <div className="grid grid-cols-2 md:grid-cols-3 px-2 gap-5">
                  {e.courses.map((course) => (
                    <a
                      className="flex flex-col bg-black rounded-xl w-full cursor-pointer"
                      href={course.url}
                    >
                      <ImageLoader
                        src={
                          "https://www.webfx.com/wp-content/uploads/2022/08/github-logo.png"
                        }
                        style={{
                          width: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                        }}
                        className={"rounded-t-xl"}
                      />
                      <div className="flex flex-col p-3">
                        <h3 className="text-xl font-bold">
                          {course.course_no}
                        </h3>
                        <p className="text-md">{course.course_title}</p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
function Notes() {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-col rounded-[10px] border-2 md:border-4 border-black w-full">
      <div
        className={`bg-black w-full p-4 pt-2 text-left font-mono cursor-pointer`}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div className="flex flex-row space-between w-full items-center">
          <h1 className="text-lg md:text-2xl font-bold w-full">Notes</h1>
          <IconButton>
            <div className="text-sm md:text-2xl">
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                color="white"
              />
            </div>
          </IconButton>
        </div>
        <h3 className="text-sm md:text-lg">
          During my undergraduate, I made notes for all theory courses using
          Microsoft Word, which was helpful for my classmates and juniors.
        </h3>
      </div>
      {expanded && (
        <div className="flex flex-col gap-5 justify-start items-start w-full">
          {notes.map((e) => (
            <div className="flex flex-col gap-5 p-4 text-left">
              <h1 className="text-base md:text-xl font-bold"># {e.term}</h1>
              <div className="grid grid-cols-2 md:grid-cols-3 px-2 gap-5">
                {e.courses.map((course) => (
                  <a
                    className="flex flex-col bg-black rounded-xl w-full cursor-pointer"
                    href={course.url}
                  >
                    <ImageLoader
                      src={
                        "https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/53/bc/2e/53bc2e28-e8f9-6fde-8ecb-f12584532d48/AppIcon-0-0-1x_U007emarketing-0-7-0-sRGB-0-0-0-85-220-0.png/1200x630wa.png"
                      }
                      style={{
                        width: "100%",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                      className={"rounded-t-xl"}
                    />
                    <div className="flex flex-col p-3">
                      <h3 className="text-xl font-bold">{course.course_no}</h3>
                      <p className="text-md">{course.course_title}</p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default function Academics() {
  return (
    <Zoom in={true}>
      <div className="flex flex-col gap-5 w-[90vw] lg:w-[45vw]">
        <Notes />
        <Repositories />
      </div>
    </Zoom>
  );
}
