import MarkdownPreview from "Components/Markdown/MarkdownPreview";
import ImageLoader from "Components/ImageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { IconButton } from "@mui/material";
import {
  faArrowUpRightFromSquare,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

export default function ProfileCard({}) {
  return (
    <div className="flex flex-col m-auto justify-center">
      <div className="flex flex-col gap-5 w-[90vw] lg:w-[45vw]">
        <div className="flex flex-col bg-black shadow-xl gap-5 relative rounded-[10px]">
          <div className="flex flex-row gap-0 md:gap-2 absolute z-10 top-0 md:top-2 right-0 md:right-2">
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faEnvelope} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faGithub} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faYoutube} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faLinkedin} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faFacebook} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faInstagram} color="white" />
              </div>
            </IconButton>
            <IconButton href="mahirlabibdihan">
              <div className="text-sm md:text-2xl">
                <FontAwesomeIcon icon={faTwitter} color="white" />
              </div>
            </IconButton>
          </div>
          <div className="flex flex-col z-0 m-0 p-0">
            <ImageLoader
              src="/logo.png"
              style={{
                width: "100%",
                objectFit: "cover",
                aspectRatio: "1920/800",
              }}
              className={"rounded-t-[10px]"}
            />
            {/* <div className="w-full bg-[#444444] h-[.06rem]"></div> */}
            <div className="w-full bg-[#222222] flex flex-row rounded-b-[10px]">
              <div className="w-full text-left py-2 pb-3">
                <div className="flex flex-row">
                  <div className="w-[37vw] lg:w-[15vw] h-full"></div>
                  <h1 className="font-bold font-mono text-2xl lg:text-4xl px-4 w-full">
                    Mahir Labib Dihan
                  </h1>
                </div>
                <div className="flex-row flex">
                  <div className="w-[37vw] lg:w-[15vw] h-full "></div>
                  <h3 className="w-full text-sm lg:text-xl font-mono px-4">
                    CSE Undergraduate at <span className="md:hidden">BUET</span>
                    <span className="hidden md:inline">
                      Bangladesh University of Engineering and Technology
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute z-10 bottom-2 md:bottom-3 left-3 md:left-4 flex">
            <ImageLoader
              src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/mahirlabibdihan.jpg"
              style={{
                //   width: "100%",
                //   width: "35vw",
                // objectFit: "cover",
                //   aspectRatio: "1920/1080",
                borderRadius: "50%",
              }}
              className="w-[24vw] lg:w-[10vw]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
