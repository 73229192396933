import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navButtons = [
  {
    title: "Timeline",
    url: "/",
  },
  {
    title: "Resume",
    url: "/resume",
  },
  {
    title: "Projects",
    url: "/projects",
  },
  {
    title: "CP Stats",
    url: "/cp_stats",
  },
  {
    title: "Academics",
    url: "/academics",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];
function NavButton({ title, url }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(url)}
      className={`py-3 text-sm md:text-2xl md:font-bold  flex flex-row items-center gap-3 font-semibold border-b-4 ${
        url === location.pathname
          ? "border-white text-white"
          : "border-black hover:border-[#999999] text-[#999999] transition-all duration-500"
      } cursor-pointer`}
    >
      {title}
    </div>
  );
}
export default function Navbar() {
  return (
    <div className="flex flex-row bg-black justify-between px-4 md:px-6 rounded-[10px] w-[90vw] lg:w-[45vw] ">
      {navButtons.map((navButton, index) => (
        <NavButton title={navButton.title} url={navButton.url} />
      ))}
    </div>
  );
}
