import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan | Contact</title>
      </Helmet>
      <div className="flex flex-col gap-10">
        <div className="text-2xl font-semibold">
          Feel free to contact me at{" "}
          <a
            href="mailto:mahirlabibdihan@gmail.com"
            className="text-blue-500 hover:underline"
          >
            mahirlabibdihan@gmail.com
          </a>
        </div>
      </div>
    </>
  );
}
