import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "Components/ImageLoader";
import { useEffect, useState } from "react";
import { IconButton, Zoom } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

function Container({ title, children }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Zoom in={true}>
      <div
        className={`flex flex-col gap-4  bg-black rounded-[10px] px-4 py-2 text-left ${
          expanded ? "pb-4" : "pb-2"
        }`}
      >
        <div
          className="flex flex-row space-between w-full items-center cursor-pointer"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <h1 className={`text-lg md:text-2xl font-bold font-mono w-full `}>
            {title}
          </h1>
          <IconButton>
            <div className="text-sm md:text-2xl">
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                color="white"
              />
            </div>
          </IconButton>
        </div>
        {expanded && <>{children}</>}
      </div>
    </Zoom>
  );
}
function Codeforces() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2    ">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/codeforces_logo.png"
            className="w-[2rem]"
          />
          Codeforces
        </div>
      }
    >
      <div className="flex flex-col gap-1">
        <h1 className="text-base md:text-xl font-bold font-mono w-full">
          Handle:{" "}
          <a
            className="underline  transition-all duration-500"
            href="https://codeforces.com/profile/mahirlabibdihan"
          >
            mahirlabibdihan
          </a>{" "}
        </h1>
        <h1 className="text-base md:text-xl font-bold font-mono w-full">
          Problems Solved: 973 (Max Rating: 2200)
        </h1>
        <h1 className="text-base md:text-xl font-bold font-mono w-full">
          Contests Participated: 75 (Max Rating: 1652)
        </h1>
      </div>
    </Container>
  );
}
function HackerRank() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2    ">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/HackerRank_logo.png"
            className="w-[2rem]"
          />
          HackerRank
        </div>
      }
    >
      <div className="flex flex-col gap-5"></div>
    </Container>
  );
}
function HackerEarth() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2    ">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/HackerEarth_logo.png"
            className="w-[2rem]"
          />
          HackerEarth
        </div>
      }
    >
      <div className="flex flex-col gap-5"></div>
    </Container>
  );
}
function LeetCode() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/LeetCode_Logo.png"
            className="w-[2rem]"
          />
          LeetCode
        </div>
      }
    >
      <div className="flex flex-col gap-5"></div>
    </Container>
  );
}
function CodeChef() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/codechef_logo.png"
            className="w-[2rem]"
          />
          CodeChef
        </div>
      }
    >
      <div className="flex flex-col gap-5"></div>
    </Container>
  );
}
function AtCoder() {
  return (
    <Container
      title={
        <div className="flex flex-row items-center gap-2">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/atcoder_logo.png"
            className="w-[2rem]"
          />
          AtCoder
        </div>
      }
    >
      <div className="flex flex-col gap-5"></div>
    </Container>
  );
}
export default function CP() {
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan | Competitive Programming</title>
      </Helmet>
      <div className="flex flex-col gap-5 w-[90vw] lg:w-[45vw] ">
        <Codeforces />
        <HackerRank />
        <HackerEarth />
        <LeetCode />
        <CodeChef />
        <AtCoder />
      </div>
    </>
  );
}
