import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "Components/ImageLoader";
import { useEffect, useState } from "react";
import { IconButton, Zoom } from "@mui/material";
import {
  faChevronDown,
  faChevronUp,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
function Container({ title, children }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Zoom in={true}>
      <div
        className={`flex flex-col gap-4  bg-black rounded-[10px] px-4 py-2 text-left ${
          expanded ? "pb-4" : "pb-2"
        }`}
      >
        <div
          className="flex flex-row space-between w-full items-center cursor-pointer"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <h1 className={`text-lg md:text-2xl font-bold font-mono w-full `}>
            {title}
          </h1>
          <IconButton>
            <div className="text-sm md:text-2xl">
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                color="white"
              />
            </div>
          </IconButton>
        </div>
        {expanded && <>{children}</>}
      </div>
    </Zoom>
  );
}
function ResearchExperience() {
  return (
    <Container title="Research Experience">
      <h1 className="text-base md:text-xl font-bold font-mono text-[#dddddd] ">
        Next Generation Location Services | Where Maps Meet LLMs
      </h1>

      <ul className="pl-6 md:pl-10 list-disc">
        <li className="text-sm md:text-lg font-mono text-[#cccccc]">
          Undergraduate thesis project
        </li>
        <li className="text-sm md:text-lg font-mono text-[#cccccc]">
          Supervisor: Dr. Mohammed Eunus Ali (Professor, BUET)
        </li>
        <li className="text-sm md:text-lg font-mono text-[#cccccc]">
          Collaborators:
          <ul className="pl-6 md:pl-10 list-square">
            <li className="text-sm md:text-lg font-mono text-[#cccccc]">
              - Dr. Muhammad Aamir Cheema (Associate Professor, Monash
              University)
            </li>
            <li className="text-sm md:text-lg font-mono text-[#cccccc]">
              - Md Rizwan Parvez (Ph.D. student, UCLA)
            </li>
            <li className="text-sm md:text-lg font-mono text-[#cccccc]">
              - Hasebul Hasan (Adjunt lecturer, BUET)
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-sm md:text-lg font-mono text-[#999999] flex flex-wrap gap-2 items-center">
        <FontAwesomeIcon icon={faTag} color="yellow" />{" "}
        <code className="bg-[#222222] p-1 px-2 rounded-sm">
          Natural Language Processing
        </code>
        <code className="bg-[#222222] p-1 px-2 rounded-sm">LLMs</code>
        <code className="bg-[#222222] p-1 px-2 rounded-sm">
          Prompt Engineering
        </code>
        <code className="bg-[#222222] p-1 px-2 rounded-sm">Geospatial</code>
      </div>
    </Container>
  );
}
function WorkExperience() {
  return (
    <Container title="Work Experience">
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row">
          <a
            className="text-base md:text-xl font-bold font-mono text-[#dddddd] hover:text-green-700 hover:border-green-700 transition-all duration-500"
            href="https://brainlytic.org/"
          >
            Brainlytic
          </a>
        </div>
        <a href="https://brainlytic.org/">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/brainlytic.png"
            alt="Brainlytic"
            className=""
          />
        </a>
        <div className="flex flex-row justify-between w-full">
          <h4 className="text-base md:text-xl font-mono text-[#cccccc]">
            Fullstack Developer
          </h4>
          <h4 className="text-base md:text-xl font-mono text-[#aaaaaa]">
            NOV '22 ~ AUG '23
          </h4>
        </div>
        <ul className="pl-6 md:pl-10 list-disc">
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Worked with seniors from CSE 18 and Azman Amin Protik from Cefalo
            Bangladesh Ltd, under the supervision of Dr. Anindya Iqbal.
          </li>
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Implemented Test, Progress Tracking and Unsolved Problems feature.
            Also did some bug fixing and code refactoring.
          </li>
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Worked on the backend, frontend, and database. Used React JS for the
            frontend, Node and Express JS for the backend, and PostgreSQL for
            the database.
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-3 justify-start">
        <div className="flex flex-row">
          <a
            className="text-xl md:text-3xl font-bold font-mono text-[#dddddd] hover:text-green-700 hover:border-green-700 transition-all duration-500"
            href="https://www.fiverr.com/"
          >
            Fiverr
          </a>
        </div>
        <a href="https://www.fiverr.com/">
          <ImageLoader
            src="https://mahirlabibdihan-public.s3.ap-southeast-2.amazonaws.com/images/fiverr.png"
            alt="Fiverr"
            className=""
          />
        </a>
        <div className="flex flex-row justify-between w-full">
          <h4 className="text-base md:text-xl font-mono text-[#cccccc]">
            Freelancer
          </h4>
          <h4 className="text-base md:text-xl font-mono text-[#aaaaaa]">
            FEB '21 ~ JUL '21
          </h4>
        </div>
        <ul className="pl-6 md:pl-10 list-disc">
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Promoted to Level 1 Seller within 2 months.
          </li>
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Worked on 30+ OpenGL Projects. Mostly 2D games and simulations.
          </li>
          <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
            Also worked on some C++, Java and Python projects.
          </li>
        </ul>
      </div>
    </Container>
  );
}

function Degree({ degree, institution, date, result, extra }) {
  return (
    <div className="md:pl-1 flex flex-col gap-1 md:gap-1 justify-start">
      <div className="flex flex-row">
        <h1 className="!leading-normal text-base md:text-xl font-bold font-mono text-[#dddddd]">
          {degree}
        </h1>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-1 w-full">
        <h1 className="!leading-tight text-sm md:text-lg font-mono text-[#aaaaaa] w-full md:w-[70%]">
          {institution}
        </h1>
        <h4 className="!leading-tight text-sm md:text-xl font-mono text-[#dddddd]">
          {date}
        </h4>
      </div>
      <h4 className="!leading-tight text-sm md:text-lg font-mono text-[#aaaaaa] w-[70%]">
        {result}
      </h4>
      {extra && (
        <h4 className="!leading-tight text-sm md:text-lg font-mono text-[#aaaaaa] w-[70%]">
          {extra}
        </h4>
      )}
    </div>
  );
}
function Education() {
  return (
    <Container title={"Education"}>
      <div className="flex flex-col gap-5">
        <Degree
          degree="B.Sc. in Computer Science and Engineering"
          institution="Bangladesh University of Engineering and Technology (BUET)"
          date="FEB '20 ~ PRESENT"
          result="CGPA: 3.96/4.00"
        />
        <Degree
          degree="Higher Secondary School Certificate (HSC)"
          institution="Notre Dame College"
          date="2019"
          result="GPA: 5.00/5.00"
        />
        <Degree
          degree="Secondary School Certificate (SSC)"
          institution="Cumilla Zilla School"
          date="2017"
          result="GPA: 5.00/5.00"
        />
        <Degree
          degree="Junior School Certificate (JSC)"
          institution="Cumilla Zilla School"
          date="2014"
          result="GPA: 5.00/5.00"
          extra="Board Talentpool Scholarship"
        />
      </div>
    </Container>
  );
}

function Skill({ title, description }) {
  return (
    <div className="md:pl-1 flex flex-col gap-1">
      <h1 className="text-base md:text-xl font-bold font-mono text-[#dddddd]">
        {title}
      </h1>
      <h3 className="!leading-tight text-sm md:text-lg font-mono text-[#aaaaaa]">
        {description}
      </h3>
    </div>
  );
}
function Skills() {
  return (
    <Container title={"Skills"}>
      <Skill
        title="Languages"
        description="C, C++, JavaScript, Java, Python, SQL(Oracle, Postgres),
          Assembly(x86), HTML, CSS, PHP"
      />
      <Skill
        title="Frameworks and Libraries"
        description="React, Node, Express, OpenGL, Bootstrap, Tailwind CSS, Axios,
          Sequelize ORM, JavaFX, NumPy, Pandas, Pytorch"
      />
      <Skill
        title="Tools"
        description="Git, Docker, Figma, PgAdmin, Postman, VS Code, IntelliJ IDEA, PyCharm,
          Wireshark, Ghidra, Radare2, GDB"
      />

      <Skill title="Operating Systems" description="Windows, Linux(Ubuntu)" />
      <Skill
        title="Soft Skills"
        description=" Problem Solving, Teamwork, Time Management, Team Leadership"
      />
    </Container>
  );
}

function Interest({ title, description }) {
  console.log("Initial description:", description);
  useEffect(() => {
    console.log(description);
  }, [description]);
  return (
    <div className="md:pl-1 flex flex-col gap-2">
      <h1 className="text-base md:text-xl font-bold font-mono text-[#dddddd]">
        {title}
      </h1>
      <h3 className="!leading-tight text-sm md:text-lg font-mono text-[#aaaaaa]">
        <ul className="pl-6 md:pl-10 list-disc">
          {description.map((desc) => (
            <li className="text-sm md:text-lg font-mono text-[#aaaaaa]">
              {desc}
            </li>
          ))}
        </ul>
      </h3>
    </div>
  );
}
function Interests() {
  return (
    <Container title="Interests">
      <Interest
        title="Competitive Programming"
        description={[
          "My journey in this field of Computer Science started with Competitive Programming.",
          "This is the fundamental of everything I have learnt till now.",
          "I am nothing without Problem Solving.",
          "I started problem solving in Codeforces. Initially I used C to code. Within 2 months I became Specialist. Then I started learning C++.",
          "Beside Codeforces, I explored other platforms like HackerRank, HackerEarth, Leetcode, CodeChef, Atcoder etc. I also participated in various contests.",
          "After solving 900+ problems and participating in 50+ contests, I became Expert in Codeforces.",
          "Then I started participating in IUPC and ICPC contests. I participated in preliminary round of 2021, 2022, 2023 ICPC Dhaka Regional. I also participated in IUPCs organized by BUET.",
          "It's hard to continue Competitive Programming with the academic and other activities. But I will come back to this field inshallah.",
        ]}
      />
      <Interest
        title="Web Development"
        description={[
          "I started Web Development to make my personal portfolio. Initially I used a template from html5up.net. I hosted my portfolio on Github Pages.",
          "After a gap, I started learning HTML, CSS and JavaScript. Then I updated my website. Also, I explored Firebase for hosting and database. I used Firestore for database. And, I added Google Analytics to track visitors. Also, I explored the hosting service of Firebase.",
          "Until now, I just learnt what I needed for my website. But my actual journey in Web Development started when I learnt some frameworks for the Level 2 Term 2 Web Development Project. I learnt React, Node, Express, Bootstrap, Axios, Material UI etc. Also, in the Database course, I learnt about SQL databases. In my project I used Oracle Database. Also, I used PL/SQL to write functions, procedures and triggers. I used Navigate to maintain the database.",
          "After making One of the best projects, Dr. Anindya Iqbal sir offered me to work under him in Brainlytic. I worked on the frontend, backend and database of the project. I used React JS for the frontend, Node and Express JS for the backend, and PostgreSQL for the database. From Brainlytic, I learnt a lot about Production Level Web Development, Team work and Team Managment. From here I became expert in Git and Github. I also got a good idea about deployment.",
          "In Level 3 Term 2, I made my first PERN stack project. I lead the team of 6 members. The full backend and database was done by me. Contributed a little bit in the frontend. From this project I learnt about Tailwind CSS and deployment of full stack applications. I used render.com for deployment. The database was also hosted on render.com.",
          "We continued the project in Level 4 Term 1. But this time we were 3 members. I learnt ORM and refactored the previous backend from raw sql query to Sequelize ORM, which made the code base more readable.This time I used Supabase for the database. I learnt Docker and Docker Compose. I used Docker to containerize the application. Also, I learnt about CI/CD and used Github Actions for CI/CD.",
          "I usually don't participate in hackathons. But I participated in the Preliminary Round of Code Samurai 2024. We made a backend for a Train Ticket Booking System using Node, Express and PostgreSQL. We also needed to dockerize the application. We were 20th in the competition and got selected for the final round.",
          "Finally, I started working again on my personal portfolio to apply everything I have learnt in 2 years. I converted the code base from HTML, CSS, JavaScript to PERN Stack.",
        ]}
      />

      <Interest
        title="Game Development"
        description={[
          "My Game Development journey started from CLI based games in C++. Where I used graphics.h, conio.h header files. I made snake game, chrome dino game, dx-ball game, space shooter game.",
          "Then, in our CSE 102 course we were introduced to iGraphics which was a C wrapper library over OpenGL. We were given one assignment to make a simluation on sine curves using OpenGL. Also, there was a term project where I build a Dx-Ball game using iGraphics. That's when I started learning OpenGL.",
          "I further explored OpenGL and made a minimal game engine using OpenGL. Using which I created various 2D games like tetris, chess, flappy bird, carrom board, etc. I used stbimage.h for loading images, freetype for loading fonts, and irrKlang for audio.",
          "After having a good grasp on OpenGL, I started freelancing on Fiverr and made 30+ 2D games and simulations using OpenGL.",
          "During my freelancing period, I learnt shading, lighting, texturing and other advanced topics of OpenGL. Also, I learnt modern OpenGL(OpenGL 4.5). I used libraries like GLEW, GLFW, GLM etc.",
          "After having enough experience in 2D games, I tried to shift to a an actual game engine. I tried Unity and Unreal Engine for 3D games, but I wasn't comfortable with them. While learning Unity, I worked with C#.",
          "I learnt Godot Engine for 2D to participate in GameJam 2023 organized by IEEE CS, BUET. I found it very user friendly. I used GDScript for scripting. Our team made a platformer game within 1 week. Later we became 2nd runner up in the competition. After making so many games, it felt good to win something.",
          "From CLI based games to OpenGL to Godot Engine, I have explored a lot in this field.",
        ]}
      />
      <Interest
        title="Machine Learning"
        description={[
          "I was first introduced to NumPy and Pandas in the Numerical Methods course in Level 2 Term 1. We were given assignments on Regression, Interpolation, Intelligence, etc.",
          "Then, in Level 3 Term 2 I learned more in this field from the Artificial Intelligence course. In the sessional course, I implemented my first learning algorithm which was Decision Tree learning. Decision Tree is a Supervised learning technique. From this course I also learnt about gradient descent, training, testing and validation.",
          "Later, I learnt about Pytorch when I participated in DL Sprint 2.0 (BUET CSE Fest 2023). Our team was 17th in the final leaderboard.",
          "In Level 4 term 1, I chose my thesis topic as 'Next Generation Location Services: Where Map meets LLM'. Which is basically a LLM based project. So, I started studying about Neural Networks and LLM. I also learnt about the Transformer model and how it is used in NLP. Then I took a course on NLP(https://huggingface.co/learn/nlp-course/en) where I learnt about fine tuning models using pytorch.",
        ]}
      />
      <Interest
        title="Android Development"
        description={[
          "I have a little experience in Android Development. I made a Android Calculator App using Android Studio. I used Java for the backend. I used XML for the frontend.",
          "I learnt Android Studio even before our Java course. From the knowledge of C and C++, I was able to understand Java. So, I started Android Development.",
          "Besides, I also make my web applications responsive for mobile.",
        ]}
      />
    </Container>
  );
}
export default function Resume() {
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan | Resume</title>
      </Helmet>
      <div className="flex flex-col gap-5 w-[90vw] lg:w-[45vw] ">
        <WorkExperience />
        <ResearchExperience />
        <Education />
        <Skills />
        <Interests />
      </div>
    </>
  );
}
