import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "Components/ImageLoader";
import { useEffect, useState } from "react";
import { Divider, IconButton, Zoom } from "@mui/material";
import {
  faChevronDown,
  faChevronUp,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import majorProjects from "Database/major.json";
import minorProjects from "Database/minor.json";
import { faGithub, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUpRightFromSquare,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import MarkdownPreview from "Components/Markdown/MarkdownPreview";
import { Helmet } from "react-helmet";

function ProjectCard({ project }) {
  return (
    <div className="flex flex-col gap-5 m-auto justify-center">
      <div className="flex flex-col gap-5 bg-black rounded-[10px] shadow-xl w-full">
        <ImageLoader
          src={project.image}
          style={{
            width: "100%",
            // objectFit: "cover",
            aspectRatio: "1920/1080",
          }}
        />

        <div className="flex flex-col gap-2">
          <h1 className="text-lg md:text-2xl font-bold text-left">
            {project.title}
          </h1>
          {/* <h3 className="text-base md:text-xl font-mono text-[#aaaaaa] text-left">
            {project.description}
          </h3> */}
          <code
            dangerouslySetInnerHTML={{ __html: project.description }}
            style={{ background: "transparent" }}
            className="text-base md:text-xl font-mono text-[#aaaaaa] text-left"
          />
          {/* <MarkdownPreview
            colorMode="dark"
            text={project.description}
            customStyle={{ color: "#aaaaaa", fontFamily: "mono  " }}
            className="text-base md:text-xl font-mono text-[#aaaaaa] text-left"
          /> */}
        </div>
        <div className="text-sm md:text-lg font-mono text-[#999999] flex flex-wrap gap-2 items-center">
          <FontAwesomeIcon icon={faTag} color="yellow" />{" "}
          {project.tags?.map((tag, index) => (
            <code key={index} className="bg-[#222222] p-1 px-2 rounded-sm">
              {tag}
            </code>
          ))}
        </div>
      </div>
    </div>
  );
}

function Container({ title, children }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Zoom in={true}>
      <div
        className={`flex flex-col gap-4  bg-black rounded-[10px] px-4 py-2 text-left ${
          expanded ? "pb-4" : "pb-2"
        }`}
      >
        <div
          className="flex flex-row space-between w-full items-center cursor-pointer"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <h1 className={`text-lg md:text-2xl font-bold font-mono w-full `}>
            {title}
          </h1>
          <IconButton>
            <div className="text-sm md:text-2xl">
              <FontAwesomeIcon
                icon={expanded ? faChevronUp : faChevronDown}
                color="white"
              />
            </div>
          </IconButton>
        </div>
        {expanded && <>{children}</>}
      </div>
    </Zoom>
  );
}

function MajorProjects() {
  return (
    <Container title="Major Projects">
      <div className="flex flex-col gap-5">
        {majorProjects.map((project, index) => (
          <>
            <ProjectCard key={index} project={project} />
            {index < majorProjects.length - 1 && (
              <div className="w-full h-[1px] bg-[#666666]" />
            )}
          </>
        ))}
      </div>
    </Container>
  );
}
function MinorProjects() {
  return (
    <Container title="Minor Projects">
      <div className="flex flex-col gap-10">
        {minorProjects.map((project, index) => (
          <>
            <ProjectCard key={index} project={project} />
            {/* {index < minorProjects.length - 1 && (
              <div className="w-full h-[1px] bg-[#666666]" />
            )} */}
          </>
        ))}
      </div>
    </Container>
  );
}
export default function Projects() {
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan | Projects</title>
      </Helmet>
      <div className="flex flex-col gap-5 w-[90vw] lg:w-[45vw] ">
        <MajorProjects />
        <MinorProjects />
      </div>
    </>
  );
}
