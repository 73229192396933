import MarkdownPreview from "Components/Markdown/MarkdownPreview";
import ImageLoader from "Components/ImageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { IconButton } from "@mui/material";
import {
  faArrowUpRightFromSquare,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { Zoom } from "@mui/material";
export default function EventCard({ title, description, image, date, event }) {
  return (
    <Zoom in={true}>
      <div className="flex flex-col gap-5 m-auto justify-center">
        <div className="flex flex-col gap-5 bg-black rounded-[10px] shadow-xl w-[90vw] lg:w-[45vw]">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between px-5 align-bottom">
              <h1 className="text-lg md:text-2xl font-bold text-left align-bottom py-5">
                {date}
              </h1>
              <div className="flex flex-row gap-2">
                {event.website && (
                  <IconButton href={event.website}>
                    <FontAwesomeIcon icon={faGlobe} color="white" />
                  </IconButton>
                )}
                {event.github && (
                  <IconButton href={event.github}>
                    <FontAwesomeIcon icon={faGithub} color="white" />
                  </IconButton>
                )}
                {
                  // If there is a youtube link, render the youtube icon
                  event.youtube && (
                    <IconButton href={event.youtube}>
                      <FontAwesomeIcon icon={faYoutube} color="white" />
                    </IconButton>
                  )
                }
                {event.url && (
                  <IconButton href={event.url}>
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      color="white"
                    />
                  </IconButton>
                )}
              </div>
            </div>

            <ImageLoader
              src={image}
              style={{
                width: "100%",
                // objectFit: "cover",
                aspectRatio: "1920/1080",
              }}
            />
          </div>

          <div className="flex flex-col gap-0">
            <h1 className="text-lg md:text-2xl font-bold text-left px-5">
              {title}
            </h1>
            <h3 className="text-base md:text-xl font-mono text-[#aaaaaa] text-left p-5 pt-2">
              {description}
            </h3>
            {/* <MarkdownPreview
              colorMode="dark"
              text={description}
              customStyle=""
            /> */}
          </div>
        </div>
      </div>
    </Zoom>
  );
}
