import EventCard from "Components/Cards/EventCard";
import updates from "Database/updates.json";
import { Helmet } from "react-helmet";
export default function Timeline() {
  return (
    <>
      <Helmet>
        <title>Mahir Labib Dihan</title>
      </Helmet>
      <div className="flex flex-col gap-10">
        {updates.map(
          (update, index) =>
            update.image && (
              <EventCard
                key={index}
                title={update.title}
                description={update.description}
                image={update.image}
                date={update.date}
                event={update}
              />
            )
        )}
      </div>
    </>
  );
}
