import React from "react";
import DefaultMarkdownPreview from "@uiw/react-markdown-preview";
import katex from "katex";
import "katex/dist/katex.css";
import { getCodeString } from "rehype-rewrite";
import "./styles.scss";

const MarkdownPreview = ({ colorMode, text, customStyle, className }) => {
  return (
    <div data-color-mode={colorMode} className="text-2xl">
      <div className="wmde-markdown-var "> </div>
      <DefaultMarkdownPreview
        source={text}
        wrapperElement={{
          "data-color-mode": colorMode,
        }}
        // className="p-5 text-3xl text-left"
        className={className}
        style={{
          // fontSize: "1.4rem",
          //   background: colorMode === "dark" ? "black" : "white",
          ...customStyle,
        }}
        components={{
          code: ({ children = [], className, ...props }) => {
            if (
              typeof children === "string" &&
              /^\$\$(.*)\$\$/.test(children)
            ) {
              const html = katex.renderToString(
                children.replace(/^\$\$(.*)\$\$/, "$1"),
                {
                  throwOnError: false,
                }
              );
              return (
                <code
                  dangerouslySetInnerHTML={{ __html: html }}
                  style={{ background: "transparent" }}
                />
              );
            }
            const code =
              props.node && props.node.children
                ? getCodeString(props.node.children)
                : children;
            if (
              typeof code === "string" &&
              typeof className === "string" &&
              /^language-katex/.test(className.toLocaleLowerCase())
            ) {
              const html = katex.renderToString(code, {
                throwOnError: false,
              });
              return (
                <code
                  style={{ fontSize: "150%" }}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              );
            }
            return <code className={String(className)}>{children}</code>;
          },
        }}
      />
    </div>
  );
};
export default MarkdownPreview;
